<template>
  <div class="fixed bottom-4 right-1">
    <a
      ref="buttonLink"
      href="https://wa.me/16285291982?text=Interesado%20en%20Samsara"
      class="w-[60px] h-[60px] shadow-xl rounded-full block"
      ><img
        class="w-full"
        src="https://images.ctfassets.net/o7f4mcn7rwzx/44Zye0KBZE8t3HhtihqAOy/5340f1768add0903dbefeaaf2b67988a/Whatsapp-green.png"
    /></a>
  </div>
</template>
<script setup>
  const { analyticsTrackLink } = useAnalytics();
  const buttonLink = ref(null);
  const { isMobile } = useDevice();
  const { locale } = useI18n();

  onMounted(() => {
    analyticsTrackLink(buttonLink.value, 'Form Started', {
      form_type: 'whatsapp',
      form_url: 'https://wa.me/16285291982?text=Interesado%20en%20Samsara',
      is_mobile: isMobile,
      locale_site: locale.value,
    });
  });
</script>
